<template>
  <div class="hello">
    <div class="header">
			<div style="position: absolute;left:20px;display: flex;align-items: center;">
				<img src="@/assets/icon/icon_logo.png" style="width:35px;margin-right:10px;">
				<span style="font-family: '标题';font-size:20px;">彭州市水务局</span>
        <div style="width:200px;line-height:70px;margin-left:80px;margin-top:-5px;font-size: 18px;">{{dateFormat(date)}}</div>
			</div>
      <div style="font-family: '标题';font-size: 30px;color: #fff;">彭州市智慧物联感知平台</div>
      <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <div  style="margin-right:50px;display: flex;justify-content: center;align-items: center;">
              <img src="@/assets/leftIcons/icon_touxiang_xiao.png" style="width:40px;" alt="">
              <div style="margin:10px;color:#fff;font-size:14px;">登录人：{{ uname }}</div>
              <i class="el-icon-arrow-down el-icon--right" style="width:5px;font-size:14px;margin-left:15px;margin-right:-20px;"></i>
            </div>
          </div>
          <el-dropdown-menu class="user-dropdown">
            <!-- <router-link to="/dashboard">
              <el-dropdown-item>
                首页
              </el-dropdown-item>
            </router-link> -->
            <router-link to="/login">
              <el-dropdown-item>
								<span class="icon iconfont icon-header-exit"></span>
                <span style="display:block;" @click="onclickss">彭州市统一应用整合平台</span>
              </el-dropdown-item>
            </router-link>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <dons ref="dongs"/>
    <div class="left_flex infinite-list"  style="overflow:auto">
      <div class="items infinite-list-item" v-for="(item,index) in list" :key="index" @click="handclickColor(index,item)" :class="active === index?'redcolor':'blueColor'">
        <div class="btn_right"><span>{{ item.total }}</span></div>
        <div class="iconFlex">
          <img :src="require('@/assets/leftIcons/' + item.icon+ '.png')" style="width:30px;height:30px;">
          <span style="margin-top:10px">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="top_boder" v-if="nijiandianwei">
      <svg-icon icon-class="eye"/>
      <div :class="active1===index?'item_div1':'item_div'" v-for="(item,index) in topList" :key="index" @click="onclickbtn(index)">
          <div>{{item.name}}</div>
          <div style="margin-top:6px;font-size:14px;">{{item.num}}</div>
      </div>
    </div>
  </div>
</template> 

<script>
import dons from '../components/VideoMap.vue'
import flvjs from 'flv.js'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      uname:'测试',
      dongsont:0,
      nijiandianwei:false,
      restaurants: [],
      state1: '',
      state2: '',
      itemLists:{},
      date:new Date(),
      listshow:false,
      namesou:'',
      donsd:'../assets/left_icon/icon1.png',
      list:[],
      url:'@/assets/left_icon',
      num:[123,555,666,9874,1235,65,24,84,36,87,66],
      active:null,
      active1:null,
      name:'',
      nameList:['设备名称','地点','ph值','设备来源'],
      listcontent:[],
      topList:[
        {
          num:0,
          name:'水质监测'
        },
        {
          num:0,
          name:'水位监测'
        },
        {
          num:0,
          name:'流量监测'
        },
        {
          num:0,
          name:'气象监测'
        },
        {
          num:0,
          name:'土壤详情'
        },
        {
          num:0,
          name:'生物监测'
        },
      ],
      content_list:[
        {
          name:'监控设备01',
          place:'四川省广元市剑阁县',
          ph:'8.28',
          source:'生态环境局'
        },
        {
          name:'监控设备02',
          place:'四川省广元市剑阁县',
          ph:'8.28',
          source:'生态环境局'
        },
        {
          name:'监控设备03',
          place:'四川省广元市剑阁县',
          ph:'8.28',
          source:'生态环境局'
        },
        {
          name:'监控设备04',
          place:'四川省广元市剑阁县',
          ph:'8.28',
          source:'生态环境局'
        },
        {
          name:'监控设备05',
          place:'四川省广元市剑阁县',
          ph:'8.28',
          source:'生态环境局'
        },
      ],
      flvPlayer:null,
      ArrList:[],
    }
  },
  components: {
    dons
  },
  created(){
    this.uname = this.queryURLParameter('name')
    let token = this.queryURLParameter('token')
    if(this.uname && token){
      localStorage.setItem('name',this.uname)
      localStorage.setItem('token',token)
    }
    console.log('URL地址')
    this.request({
					url: '/api/link/link-data/point-data',
					method: 'get'
				}).then(res => {
					console.log(res)
          this.list = res.data;
				}).catch(err=>{
					console.log(err)
				})
  },
  mounted(){
      //显示当前日期时间
      let _this = this// 声明一个变量指向Vue实例this，保证作用域一致
          this.timer = setInterval(() => {
           _this.date = new Date(); // 修改数据date
           }, 1000)
  },
  beforeDestroy() {
       if (this.timer) {
        clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
      }
      },
  methods:{
    queryURLParameter(key){
		  // 获取参数
      var url = window.location.search;
    // 正则筛选地址栏
    var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
    // 匹配目标参数
    var result = url.substr(1).match(reg);
    //返回参数值
    return result ? decodeURIComponent(result[2]) : null;
		},
    onclickss(){
			if(window.location.href.indexOf('pzlink.zysctec.com:880') != -1){
				window.location = 'http://pzpage.zysctec.com:880'
			}else{
				window.location = 'http://pzpagetest.zysctec.com'
			}
    },
    querySearch(queryString, cb) {
        var restaurants = this.ArrList;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
       
        console.log('***2');
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=-1);
        };
      },
      
      handleSelect(item) {
        console.log(item)
        console.log('选中')
        this.openUrlin = item;
      },
    dateFormat(time) {
          var date=new Date(time);
          var year=date.getFullYear();
          /* 在日期格式中，月份是从0开始的，因此要加0
          * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
          * */
          var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
          var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
          var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
          var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
          var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
          // 拼接
          return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      },
    souUrl(e){
      // this.$refs.dongs.backs(this.openUrlin);
      let this_ = this
      this.request({
					url: '/api/link/link-data/search-point',
					method: 'get',
          params:{
            type:this.itemLists.type,
            name:this.state1
          }
				}).then(res => {
          console.log('所有')
          console.log(res)
          if(res.data.length == 0){
            this_.ArrList = []
          }else{
            this_.ArrList= res.data.pointList.map((terminal) => {
              if(this_.itemLists.type == 21 || this_.itemLists.type == 22 || this_.itemLists.type == 23){
                return {
                  value: terminal.STNM,
                  address:terminal.STLC,
                  lat:terminal.LTTD,
                  lng:terminal.LGTD,
                };
              }
              if(this_.itemLists.type == 31){
                return {
                  value: terminal.name,
                  address:terminal.JSDD,
                  lat:terminal.lat,
                  lng:terminal.lng,
                };
              }
              if(this_.itemLists.type == 41){
                return {
                  value: terminal.name,
                  address:terminal.address,
                  lat:terminal.lat,
                  lng:terminal.lng,
                };
              }
              if(this_.itemLists.type == 43 || this_.itemLists.type == 44){
                return {
                  value: terminal.STNM,
                  address:terminal.STLC,
                  lat:terminal.LTTD,
                  lng:terminal.LGTD,
                };
              }
            });
          }
					console.log(this_.ArrList)
					console.log('加班')
				})
    },
    biaozhu(e){
      console.log(e)
      console.log('滴滴')
      this.$refs.dongs.backs(e);
    },
    getImgUrl (img) {
      return require("@/assets/leftIcons/icon" + img+ ".png");
    },
    onclickbtn(index){
      this.active1 = index
    },
    getContent(e){
      console.log(e)
      console.log('这是数据')
      this.listcontent = []
      this.listcontent = e.pointList;
      // this.restaurants = e.pointList
      this.$refs.dongs.biaoAll(e);
    },
    handclick(){
				this.request({
					url: 'http://test-dayi1.zysctec.com/api/stic-icon/v1',
					method: 'get',
				}).then(res => {
					console.log(res)
				}).catch(err=>{
					console.log(err)
				})
    },
    handclickColor(index,item){
      this.active = index
      this.itemLists = item
      console.log(item)
      console.log('哈哈哈哈哈')
      this.listshow = true;
        this.request({
					url: '/api/link/link-data/search-point',
					method: 'get',
          params:{
            type:item.type
          }
				}).then(res => {
          this.ArrList= res.data.pointList.map((terminal) => {
            if(item.type == 21 || item.type == 22 || item.type == 23){
              return {
                value: terminal.STNM,
                address:terminal.STLC,
                lat:terminal.LTTD,
                lng:terminal.LGTD,
              };
            }
            if(item.type == 31){
              return {
                value: terminal.name,
                address:terminal.JSDD,
                lat:terminal.lat,
                lng:terminal.lng,
              };
            }
            if(item.type == 41){
              return {
                value: terminal.name,
                address:terminal.address,
                lat:terminal.lat,
                lng:terminal.lng,
              };
            }
            if(item.type == 43 || item.type == 44){
              return {
                value: terminal.STNM,
                address:terminal.STLC,
                lat:terminal.LTTD,
                lng:terminal.LGTD,
              };
            }
          });
					console.log(this.ArrList)
					console.log('加班')
				})
            // this.$refs.dongs.showheppe();
      //     if(id==100){
      //       this.listshow = false;
      //       this.nijiandianwei = true
      //     }else{
      // this.listshow = true;
      //       this.nijiandianwei = false
      //     }
      this.getContent(item)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
   .right-menu{
    position: absolute;
    right:-10px;
    cursor:pointer;
  }
  .avatar-container {
     

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
          color:#fff;
          margin-top:-6px;
        }
      }
    }
  .iconFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item_div{
    width:220px;height:130px;
    border-radius: 10px;
    background:#f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:10px;
    color:#315298;
    font-size:26px;
    font-weight: 700;
    cursor:pointer;
  }
  .item_div1{
    width:220px;height:130px;
    border-radius: 10px;
    background:#2d4c8d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:10px;
    color:#fff;
    font-size:26px;
    font-weight: 700;
    cursor:pointer;
  }
  .top_boder{
    width:80%;
    height:220px;
    border-bottom-left-radius: 40px;
    position: absolute;
    top:72px;
    right:0;
    background:#FFF;
    box-shadow: -10px 10px 20px 10px rgba(0, 0, 0, .1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contents{
    display: flex;
    padding:12px;
    margin-bottom:10px;
    background:#edf1f8;
    border-radius: 12px;
    cursor:pointer;
  }
  .item_title{
    flex:1;
    overflow:hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
        font-size:15px;
  }
  .item_title1{
    flex:1;
    overflow:hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
        font-size:15px;
  }
  .item_title2{
    width:20%;
    overflow:hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
        font-size:15px;
  }
  .item_conent{
    width:100%;
    height:50px;
    border-radius: 8px;
  }
  .titleList{
    margin-top:20px;
    width:100%;
    height:50px;
    display: flex;
    color:#fff;
  }
  .input_flex{
    display: flex;
  }
  .listbuild{
    background:#273047;
    width:500px;
    height:100%;
    position: absolute;
    top:72px;
    left:140px;
    padding:20px;
    color:#fff;
  }
  .hello{
    height:100%;
  }
  .header{
    height:70px;
	background-color: #1c7ff9;
    
    display: flex;
    justify-content: center;
    align-items: center;
    div{
      color:#fff;
      font-size:32px;
    }
  }
  .items{
    width:140px;
    height:100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    background:#355dbc;
    cursor:pointer;
    position: relative;
    border-bottom:.1px solid #000;
  }
  .left_flex{
    position: absolute;
    top:72px;
    left:0;
    height:100%;
    overflow: hidden;
    z-index:9999;
  }
    .items{
      &:hover {
        // you can use $subMenuHover
      background-color:#1c76f9 !important;
      }
    }
    .redcolor{
      background:#1c76f9;
    }
    .blueColor{
      background:#273047;
    }
    .btn_right{
      width:48px;
      height:20px;
      background-image: url(../assets/leftIcons/icon_jiaobiao_orange.png);
      background-size:100% 100%;
      position: absolute;
      right:0;
      top:0;
      border-bottom-left-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:13px;
    }
    ::v-deep element.style{
      overflow: hidden;
    }
    .video-box{
      z-index:999999999999999999;
      width:400px;
    }
  .title_wig{
	font-size: 34px;
	color: #3ee9f8;
  }
  ::v-deep .custom-table .el-table__header th {
  background-color: #5e7bc2; /* 设置表头背景颜色 */
  color:#fff;
}
::v-deep .customRow{
  background-color:#2d3854;
  border-color:#5670b1;
  color:#fff;
}

.el-table {
  border-color:#5670b1;
}
 
::v-deep.el-table th {
  border-color:#5670b1;
  cursor: pointer;
}
 
::v-deep.el-table td {
  border-color:#5670b1;
  cursor: pointer;
}
::v-deep .el-table{
  border-color:#5670b1;
}
::v-deep .el-table tbody tr:hover>td {
            background-color:unset !important //修改成自己想要的颜色即可
        }
::v-deep .el-table__body-wrapper{
  margin-top:-2px;
}
::v-deep .el-table--border::after{
  width:0;
}
::v-deep .el-table::before {
  height:0;
}

.chaxuns{
  width:100px;height:40px;
  display: flex;
  margin-left:20px;
  align-items: center;
  justify-content: center;
  background:#1c76f9;
  font-size:16px;
  cursor: pointer;
}
::v-deep .el-input__inner {
  border: 1px solid #fff;; 
  background-color: rgba(255, 255, 255, 0);
  color:#fff;
}
.botJos{
  overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
}
</style>
