<template>
	<!-- 百度地图  -->
   <div style="position: relative;">
 		 <div id="mapDiv"  style="width:100%;height:1000px;"></div>
		<div class="instankuang" v-show="stationDetailsModalShow">
			<div class="topBack" v-show="listshows">
				<!-- <span class="title">{{xiangqingContent.name}}</span> -->
			</div>
				<div class="video-box" v-show="showdongs">
					<video id="videoElement" controls autoplay muted width="600" height="200px">
					</video>
				</div>
				<ul class="infinite-list" v-if="listshows" style="overflow:auto;height:390px;padding-right:30px;">
					<div class="posins">{{ stationModalForm.STNM || stationModalForm.name}}</div>
					<li v-for="(item,index) in xiangqingContent.dataList"  :key="index" style="margin-top:6px;list-style:none;display: flex;line-height:28px;justify-content: space-between">
						<div style="width:200px;">
							<div style="font-size:15px;font-weight: bold;;">{{ item.title }}：</div>
						</div>
						<div>
							{{ item.value }}
						</div>
					</li>
				</ul>
			<img src="@/assets/icon/icon_quxiao.png" style="width:16px;height:16px;position: absolute;right:30px;top:24px;cursor:pointer;" @click="sops">
		</div>
		

   </div>
 </template>
 
 <script>
 import flvjs from 'flv.js'
 import AMapLoader from "@amap/amap-jsapi-loader";
 import axios from 'axios'
// import sanxigou from './山溪沟.json'
 export default {
	 data() {
		 return {
			showdongs:false,
			listshows:false,
			vUrl:'http://video.zysctec.com:180/flv/live/stream_11.flv',
			showss:false,
			flvPlayer:null,
			xiangqingContent:{
				dataList:[]
			},
			 map : null,
			 mouseTool : null,
			 overlays : [],
			 auto : null,
			 placeSearch : null, 
			 checkList: [],
			 checkLists: '电子地图',
			 lnglats: [
				[103.5122, 30.573004],
				[103.5123, 30.573004],
				[103.5125, 30.573004],
				[103.5127, 30.573004]
			],
			markers1:[],
			marker:[],
			opts: {
                    subdistrict: 0,   //获取边界不需要返回下级行政区
                    extensions: 'all',  //返回行政区边界坐标组等具体信息
                    level: 'district'  //查询行政级别为 区
                },
			 btn_list:[],
			 btnTop:['电子地图','卫星地图'],
			 rightList:{},
			 stationDetailsModalShow:false,
			stationModalForm: {},
			a:0,
			b:0,
			disProvince:null, districtCode:'310112',

			polygons : [],
			district: null,
			polygonArr:[],
			zoom:null,
			saveList:{},
			drawLinList:[],
			markerList:[]
		 }
	 },
	 created(){
		console.log('save')
	 },
	 mounted(){
		this.initMap()
		this.btn_list = []
	 },
	 beforeDestroy(){
	 },
	 updated(){
	 },
	 	methods : {
			//销毁地图
			sops(){
				this.stationDetailsModalShow=false;
				this.flvPlayer.destroy();
			},
			play () {
				this.flvPlayer.play();
			},
			//右侧栏接口
			biaoAll(e){
				console.log(e)
				console.log('全部齐全')
				this.carGPSIP(e)
				this.stationDetailsModalShow = false;
			},
			// 获取水电站详情
			async getStationDetail(data,index) {
				this.stationModalForm = data;
				console.log(data)
				this.xiangqingContent = data;
				console.log('吃鸡儿')
				this.stationDetailsModalShow = true;
				this.listshows = true;
				// const result = await apiHsHydropowerStationVideo({ id: data.id });
				this.videos =  [];
				this.showss = true;
				// if(this.xiangqingContent.info.video.length>0){
				// 	this.showdongs = true;
				// }else{
				// 	this.showdongs = false;
				// }
				if (flvjs.isSupported()) {
					var videoElement = document.getElementById('videoElement');
					this.flvPlayer = flvjs.createPlayer({
						type: 'flv',
						isLive: true,
						hasAudio: false,
						url: this.stationModalForm.info.video[0].value		// 自己的flv视频流
					});
						this.flvPlayer.attachMediaElement(videoElement);
						this.flvPlayer.load();
						this.flvPlayer.play();
				}
			},
			showheppe(){
				this.stationDetailsModalShow = false;
			},
			//定位渲染
			carGPSIP(e,index) {
				this.saveList = e
				let newMarker = this.map.getOverlays();
				this.markerList = []
				for(let i=0;i<newMarker.length;i++){
					if(newMarker[i].id != 9999){
						this.map.removeOverLay(newMarker[i])
					}
				}
				e.pointList.forEach(res=>{
					if(!res.LGTD || !res.LTTD){
						return;
					}
					var icon = new T.Icon({
						iconUrl: require(`@/assets/icon/${e.icon}.png`), // 图片地址
						iconSize: new T.Point(25, 35), // 图标的大小
						iconAnchor: new T.Point(13, 31) // 图标的锚点
					});

					var point = new T.LngLat(res.LGTD, res.LTTD);



					var marker = new T.Marker(point, {icon: icon});// 创建标注
					console.log('res')
					console.log(res)
					// 将标注内容添加到标注上
					var label = new T.Label({
						text: `<div style='position:absolute;left:-50%;transform: translateX(-50%);color:#000;background:#fff;line-height:14px;'>
        ${res.STNM || ""}
        <div>`,
						position: marker.getLngLat(),
						offset: new T.Point(-4, 20) // 文字相对于图标的偏移位置
					});
					this.map.addOverLay(label);






					marker.id = e.type
					this.markerList.push(marker)
					marker.on('click', e => {
						console.log('已经点击')
						console.log(res)
						this.getStationDetail(res,index);
						
					});
				})
				for(let i=0;i<this.markerList.length;i++){
					this.map.addOverLay(this.markerList[i]);
				}
		},
			onswitch(){
				this.mapType = type;
			},
		// 销毁地图
		destroyMap() {
			this.map && this.map.destroy();
		},
		//电子图
		initMap() {
			var imageURL = 'http://t0.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=76ae536f4100a8347a432b561d73bd74';
			var lay = new T.TileLayer(imageURL, {minZoom: 1, maxZoom: 18});
			this.map = new T.Map('mapDiv', {layers: [lay]});
			this.map.centerAndZoom(new T.LngLat(103.89226, 31.163004), 10.8);
			//创建对象
			var ctrl = new T.Control.MapType();
			//添加控件
			this.map.addControl(ctrl);
			this.drawLine()
		},
		drawLine() {// 绘制边界线
			axios.get("./ss.json").then((res) => {
				let lines = res.data.features[0].geometry.coordinates[0][0]
				console.log('彭州市')
			console.log(lines)
			let style = {
				color: '#81a5b9',
				weight: 3,
				opacity: 1,
				lineStyle: 'solid', // 实线;dashed虚线
				fillColor: 'transprent',
				fillOpacity: 0 // 透明度
			}
			let points = []
			lines.forEach(line => { // lines是边界经纬度组成的数组
				var point = new T.LngLat(line[0], line[1])
				points.push(point)
			})
			// console.log(points)
			var poly = new T.Polygon(points, style)
			poly.id = 9999
			this.map.addOverLay(poly)
			});
		},
		select(e) {
			this.placeSearch.setCity(e.poi.adcode);
			this.placeSearch.search(e.poi.name);  //关键字查询查询
		}
	}
}
 </script>
 
 <style scoped lang="scss">
 .topBack{
	width:100%;height:60px;
    background:#3675fb;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding:0 10px;
	.title{
		line-height:60px;
		color:#FFF;
		font-size:18px;
		margin-left:20px;
	}
 }
 #bai-du-map {
	 overflow: hidden;
	 width: 100%;
	 height: 100vh;
	 margin: 0;
	 font-family: "微软雅黑";
 }
 .rightBel{
	width:200px;
	background:#fff;
	position: absolute;
	right:10px;
	top:6%;
	border-radius: 10px;;
	padding:20px;
	display: flex;
	flex-direction: column;
 }
    .video-box{
      z-index:999999999999999999;
      width:400px;
    }
 .topBel{
	background:#4e7cc7;
	color:#fff;
	position: absolute;
	right:30px;
	top:35px;
	padding:16px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;box-shadow: 0px 3px 21px 0px rgba(28,118,249,0.19);
 }
 .instankuang{
	background:#fff;
	width:600px;
	height:500px;
	position: absolute;
	top:20%;
	left:40%;
	z-index:99999999999999999;
	border-radius: 10px;;
	background:#f2f2f2;
	background-image: url(@/assets/icon/icon_tanchuang_tu.png);
 }
 .posins{
	font-size:20px;
	color:#fff;
	position: absolute;
	    left: 50%;
	    top: 30px;
	    transform: translate(-50%, -50%);
 }
.instankuang .el-scrollbar__wrap{
    overflow: hidden
}
::v-deep .amap-markers .amap-marker-label{
	border-style:none;
	background-color:transparent;
	color:#fff;
}
#bai-du-map .amap-marker-label{border:0 none;  white-space: nowrap; box-shadow: 0 0 5px 0 rgba(0,0,0,.3); border-radius: 5px;}

#bai-du-map .amap-marker-label:after{position: absolute; border:5px solid transparent; border-top-color: #fff; top:19px; left: 43%; content: ''; width: 0; height: 0;}
 </style>
 <style>
.amap-logo{
display: none!important;
}
.amap-copyright{
opacity:0;
}
 </style>