import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
 
// request interceptor
service.interceptors.request.use(
  config => {
    var token = localStorage.getItem('token');
    config.headers['Typeid'] = '10'
    if(token){
      console.log('有token')
      config.headers['token'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if(res.code == 140 || res.code == 141 || res.code == 142){
    //   Message.error(res.message)
    //   localStorage.removeItem('token')
    //   location.href = 'http://portal.zysctec.com:187/#/login';
    // }
    // if the custom code is not 20000, it is judged as an error.
    if(res.code === 2022) {
      return res
    } else if (res.code == 140 || res.code == 141 || res.code == 142 || res.code == 143) {
      Message({
        message: res.msg,
        type: 'error'
      })
    } else if(res.code == 160){
      Message({
        message: res.msg,
        type: 'error'
      })
    }else{
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.msg,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
