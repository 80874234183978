import Vue from 'vue'
import ElementUI from 'element-ui';
import '@/styles/index.scss' // global css
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import '@/assets/fonts/font.css'
import '@/assets/fonts/fontS.css'
import '@/assets/fonts/init.css'
import '@/icons';
Vue.use(ElementUI);
import request from '@/utils/request'
Vue.prototype.request = request
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
